import React from 'react'
import {Link} from 'gatsby'
import serviceIcon7 from '../../assets/images/services/service-icon7.png'
import serviceIcon8 from '../../assets/images/services/service-icon8.png'
import serviceIcon9 from '../../assets/images/services/service-icon9.png'
import serviceIcon10 from '../../assets/images/services/service-icon10.png'
import serviceIcon11 from '../../assets/images/services/service-icon11.png'
import serviceIcon12 from '../../assets/images/services/service-icon12.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'
import AniLink from "gatsby-plugin-transition-link/AniLink";

const OurServices = () => {
    return (
        <section className="services-area ptb-100">
            <div className="container">
                <div className="section-title pt-100">
                    <h2>Our Services</h2>
                    <p>We have experience with the state-of-the-art technologies utilizing the latest management techniques.</p>
                </div>

                <div className="row">
                    
                    <div className="col-lg-4 col-md-4 col-sm-6">

                    <AniLink 
                        paintDrip
                        hex="#ff5d22"
                         to="/capabilities/ecommerce">
                        <div className="single-services-box-item ">


                            <div className="icon">
                                <img src={serviceIcon9} alt="services" />
                            </div>

                            <h3>
                                    E-Commerce Services
                            </h3>
                            {/* <p>Is Headless E-Commerce Right For You?</p> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>

                        
                        </div>
                        </AniLink>
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-sm-6">
                       <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                         to="/capabilities/marketplace">
                        <div className="single-services-box-item">

                            <div className="icon">
                                <img src={serviceIcon10} alt="services" />
                            </div>
                            <h3>
                                    Marketplace Operations
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/capabilities/technologies" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                        </AniLink>
                    </div>
                    
                    {/* <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon12} alt="services" />
                            </div>
                            <h3>
                                <Link to="/capabilities/technologies">
                                    Email Marketing
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/capabilities/technologies" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon12} alt="services" />
                            </div>
                            <h3>
                                <Link to="/capabilities/technologies">
                                    Search Engine Optimization (SEO)
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/capabilities/technologies" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div> */}

                    <div className="col-lg-4 col-md-4 col-sm-6">
                    <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                         to="/capabilities/webdev">
                        <div className="single-services-box-item">

                            <div className="icon">
                                <img src={serviceIcon11} alt="services" />
                            </div>
                            <h3>
                                    Web/Mobile App Development
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/capabilities/technologies" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>

                        </div>
                        </AniLink>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6">
                       <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                         to="/capabilities/digital">
                         <div className="single-services-box-item">

                            <div className="icon">
                                <img src={serviceIcon8} alt="services" />
                            </div>
                            <h3>
                                    Digital Marketing
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                         </div>
                        </AniLink>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                          to="/capabilities/erp">
                         <div className="single-services-box-item">

                            <div className="icon">
                                <img src={serviceIcon7} alt="services" />
                            </div>
                            <h3>
                                    SAP Cloud Implementation 
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                          </div>
                        </AniLink>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                         to="/capabilities/devops">
                        <div className="single-services-box-item">

                            <div className="icon">
                                <img src={serviceIcon8} alt="services" />
                            </div>
                            <h3>
                                    DevOps
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                          </div>
                        </AniLink>
                    </div>
                    

                </div>
            </div>
        </section>
    )
}

export default OurServices